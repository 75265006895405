import { Ad } from '@/common/types/Ad';

const StaticUSIP = [
    '69.162.81.155', // Dallas
    '192.199.248.75', // Denver
    '162.254.206.227', // Miami
    '209.142.68.29', // Chicago
    '207.250.234.100', // Minneapolis
    '184.107.126.165', // Montreal
    '206.71.50.230', // New York
    '65.49.22.66', // San Francisco
    '23.81.0.59', // Seattle
    '207.228.238.7', // Washington DC
];

export class AdsRepository {
    public getAll(): Promise<Ad.FetchResponse> {
        const url = process.env.VUE_APP_TRAFFIC_URL as string;
        return fetch(url, {
            mode: 'cors',
            headers: {
                'X-Forwarded-For': StaticUSIP[Math.floor(Math.random() * StaticUSIP.length)],
            },
        })
            .then((resp) => resp.json());
    }
}
