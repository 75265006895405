import { Plugin } from 'vue';
import { RepositoryNames } from '@/repositories/RepositoryNames';
import SearchRepository from '@/repositories/SearchRepository';
import { AdsRepository } from '@/repositories/AdsRepository';

export const CwRepositoryFactoryPlugin: Plugin = {
    install(app): void {
        const config = {
            [RepositoryNames.SEARCH]: () => new SearchRepository(),
            [RepositoryNames.ADS]: () => new AdsRepository(),
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const instances: Partial<Record<RepositoryNames, any>> = {};

        app.config.globalProperties.$repository = {
            get<T = never>(name: RepositoryNames): T {
                if (!instances[name]) {
                    if (!config[name]) {
                        throw new Error(`Unknown repository name: ${name}`);
                    }

                    instances[name] = config[name]();
                }

                return instances[name];
            },
        };
    },
};

export interface CwRepositoryFactory {
  get<T = never>(name: RepositoryNames): T;
}
