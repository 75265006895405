import { createApp } from 'vue';
import { CwRepositoryFactoryPlugin } from '@/common/plugins/CwRepositoryFactoryPlugin';
import { store } from '@/store';
import App from './App.vue';
import router from './router';
import '@/styles/index.scss';

createApp(App)
    .use(router)
    .use(store)
    .use(CwRepositoryFactoryPlugin)
    .mount('#app');
