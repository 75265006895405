import jsonp from 'jsonp';
import { Suggestion } from '@/common/types/Suggestion';
import { QuerySuggestionsRaw } from '@/common/types/QuerySuggestionsRaw';

export default class SearchRepository {
    protected readonly suggestionsUrl = 'https://sugg.search.yahoo.net/sg/';

    public getSuggestions(query: string, limit = 10): Promise<Suggestion[]> {
        /**
         * @todo catch handler
         */

        const promise = new Promise<QuerySuggestionsRaw>((resolve) => {
            jsonp(
                `${this.suggestionsUrl}?command=${query}&nresults=${limit}`,
                {},
                (error: Error | null, json: QuerySuggestionsRaw) => resolve(json),
            );
        });

        return promise.then((response: QuerySuggestionsRaw) => response[1].map(
            (rawSuggestion) => ({
                name: rawSuggestion,
            } as Suggestion),
        ));
    }
}
