import { MutationTree } from 'vuex';
import { RootState } from '@/store/types';
import { Ad } from '@/common/types/Ad';

export const mutations: MutationTree<RootState> = {
    setAds(state, ads: Ad[]): void {
        state.ads = ads;
    },

    setClickUrl(state, clickUrl: string): void {
        state.clickUrl = clickUrl;
    },

    setIconsUrlEndpoint(state, iconUrlEndpoint: string): void {
        state.iconUrlEndpoint = iconUrlEndpoint;
    },
};
