import { createStore } from 'vuex';
import { RootState } from '@/store/types';
import { actions } from '@/store/actions';
import { mutations } from '@/store/mutations';

export const store = createStore<RootState>({
    state: {
        clickUrl: '',
        iconUrlEndpoint: '',
        ads: [],
    },
    mutations,
    actions,
});
