import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { AdsRepository } from '@/repositories/AdsRepository';
import { adsMapper } from '@/common/mappers/AdsMapper';

const adsRepository = new AdsRepository();

export const actions: ActionTree<RootState, RootState> = {
    fetchAds({ commit }) {
        return adsRepository.getAll()
            .then((data) => {
                commit('setAds', adsMapper(data.brands));
                commit('setClickUrl', data.clickUrl);
                commit('setIconsUrlEndpoint', data.iconUrl);
            })
        /**
       * @todo handle an error during fetching
       */
            .catch(() => {});
    },
};
